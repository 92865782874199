import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './../pages/Home';
import Page404 from '../pages/404';

function Routers() {
    return (
        <Router>
            <Routes>
                {/* Home */}
                <Route path="/:promptId" element={<Home />} />
                <Route path='/*' element={<Page404 />} />
            </Routes>
        </Router>
    );
}

export default Routers;
