
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { RiLoader2Fill } from "react-icons/ri";

import { HOST_API } from '../config';
import { capitalizeFirstWordLetter, FacebookShareButton, handleFindReplaceText, MarkdownBlock, TwitterShareButton } from '../utils/utils';

function Home() {
    const params = useParams();
    const [loading, setLoading] = useState(true);

    const [viewResPrompt, setViewResPrompt] = useState('');
    // const [viewResLanguage, setViewResLanguage] = useState('');
    const [viewResPromptId, setViewResPromptId] = useState('');
    const [viewResTemplate, setViewResTemplate] = useState('');
    const [viewResResult, setViewResResult] = useState('');
    const [viewResWords, setViewResWords] = useState('');
    const [viewResCreatedAt, setViewResCreatedAt] = useState('');

    useEffect(() => {

        axios
            .get(HOST_API + 'ai/view/prompt/' + params.promptId)
            .then((res) => {
                const promptData = res.data.data;
                if (res.data.code === 200) {
                    // console.log(promptData)
                    setViewResPrompt(promptData.prompt);
                    // setViewResLanguage(promptData.language)
                    setViewResPromptId(promptData.promptId)
                    setViewResTemplate(promptData.template)
                    setViewResResult(promptData.result)
                    setViewResWords(promptData.words)
                    setViewResCreatedAt(promptData.createdAt)
                    document.title = promptData.prompt;
                    setLoading(false)
                } else {
                    window.location.href = 'https://stralight.com'
                    setLoading(false)
                }
            })
            .catch((error) => {
                setLoading(false)
                window.location.href = 'https://stralight.com'
                console.log(error);
            });
    }, [params]);


    return (
        <>
            <main className="nk-pages">
                <section className="section has-mask">
                    <div
                        className="nk-mask bg-pattern-dot bg-blend-around mt-n5 mb-10p mh-50vh"
                    ></div>
                    {loading ?
                        (
                            <div className="container">
                                <div className='text-center'>
                                    <RiLoader2Fill size={40} />
                                </div>
                            </div>
                        ) :
                        (
                            <div className="container">
                                <div className="section-content">
                                    <div className="row g-gs justify-content-center">
                                        <div className="col-xxl-8 col-xl-9 col-lg-10">
                                            <div className="text-center">
                                                <h6 className="overline-title text-primary">Stralight</h6>
                                                <h1 className="title">
                                                    {viewResPrompt}
                                                </h1>
                                                <ul className="list list-row gx-2">
                                                    <li><div className="sub-text fs-5">{viewResCreatedAt}</div></li>
                                                </ul>
                                                <div className="badge bg-primary bg-opacity-10 text-primary rounded-pill"><span>{capitalizeFirstWordLetter(handleFindReplaceText(viewResTemplate, '-', ' '))}</span></div>
                                                <div className="badge bg-primary bg-opacity-10 text-primary rounded-pill"><span>{capitalizeFirstWordLetter(viewResWords)} Words</span></div>
                                            </div><br />
                                            <div className="d-flex">
                                                <div className="block-typo" style={{ width: '100%' }}>
                                                    <MarkdownBlock content={viewResResult} />
                                                    <div className="text-center mt-4">
                                                        <br />
                                                        <a href='https://app.stralight.com/auth/signup' className="btn btn-primary">Get Started with Stralight</a>
                                                    </div>
                                                </div>
                                                <ul className="btn-list gy-3 ps-xl-6 ps-lg-4 ps-3">
                                                    <li>
                                                        <FacebookShareButton url={'https://share.stralight.com/' + viewResPromptId} />
                                                    </li>
                                                    <li>
                                                        <TwitterShareButton url={'https://share.stralight.com/' + viewResPromptId} />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                </section>
            </main>
        </>
    );
}

export default Home;
