import React from 'react';
import ReactMarkdown from 'react-markdown';

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export const MarkdownBlock = ({ content }) => {
    return (
        <ReactMarkdown>{content}</ReactMarkdown>
    );
};

export const handleFindReplaceText = (string, findWord, replaceWord) => {
    const regex = new RegExp(findWord, 'g');
    return string.replace(regex, replaceWord);
};

export const capitalizeFirstWordLetter = (string) => {
    return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const FacebookShareButton = ({ url }) => {
    const handleClick = () => {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
    };

    return (
        <span onClick={handleClick} className="btn btn-link btn-soft" >
            <em className="icon fs-3 ni ni-facebook-circle"></em>
        </span>
    );
}

export const TwitterShareButton = ({ url }) => {
    const handleClick = () => {
        const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`;
        window.open(twitterUrl, '_blank');
    };

    return (
        <span onClick={handleClick} className="btn btn-link btn-soft" >
            <em className="icon fs-3 ni ni-twitter"></em>
        </span>
    );
}

// export const LinkedInShareButton = ({ url, title }) => {
//     const handleClick = () => {
//         // const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
//         const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}&title=${title}`;
//         window.open(linkedInUrl, '_blank');
//     };

//     return (
//         <span onClick={handleClick} className="btn btn-link btn-soft" >
//             <em className="icon fs-3 ni ni-linkedin-round"></em>
//         </span>
//     );
// }